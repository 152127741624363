.container {
  background-color: rgb(255, 222, 222);
  width: 100%;
  font-family: "Helvetica now" , "Helvetica" , sans-serif;
  align-items: center;
  justify-content: center;
}

.landingpage {
  height: 100vh;
  background-image: url("/src/images/wallpaperflare.com_wallpaper.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #07062a;
}

.arrowD {
  margin-top: 15%;
  margin-left: 48%;
}


.arrowD {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
}
.arrowD:hover{
  animation: MoveUpDown 1s linear ;
  cursor: pointer;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  30% {
    bottom: 20px;
  }
}