.landingOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 180px;
    color: rgb(249, 250, 253);
}

.nameBig {
    font-size: 80px;
    font-weight: bold;
    text-align: center;
    text-shadow: rgb(0, 0, 0) 1px 1px 3px;
}

.infoSmall {
    font-size: 20px;
    text-align: center;
    font-weight:lighter;
    text-shadow: rgb(0, 0, 0) 1px 1px 3px;
}

.icon1 {
    margin-right: 10px;
    margin-left: 10px;
    text-shadow: rgb(0, 0, 0) 1px 1px 3px;
}

.icon1 :hover {
    color: #EB8A3D;
  }

.iconsGroup {
    margin-top: 1%;
    margin-left: 40%;
    margin-right: 40%;
}