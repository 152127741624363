.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #103555;
    color: whitesmoke;
    height: 100vh;
    padding: 0 2% 0 2%;
}

.contact1 {
    color: #EB8A3D;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 2%;
    margin-top: 5%;
}

.contact2 {
    font-weight: bold;
    font-size: 30px;
    color: rgb(251, 251, 251);
    margin-bottom: 1%;
}

.contact3 {
    line-height: 1.6;
    color: rgb(190, 190, 190);
    font-size: 20px;
    margin-bottom: 3%;
}

.infoContainer {
    display: flex;
    flex-direction: row;
}



.linktext {
    color: rgb(5, 145, 180);
    text-decoration: none;
}

.linktext:visited {
    color: rgb(5, 145, 180);
    text-decoration: none;
}

.infoContainer {
    margin-top: 10%;
}

.blockContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 80px;
    margin-right: 80px;
}

.contactIcons {
    margin-bottom: 20px;
}

.contact5 {
    font-weight: bold;
    color: #EB8A3D;
}

.contact6 {
    color: rgb(224, 224, 224);
    font-weight: lighter;
}

.goTopButton {
    color: #EB8A3D;
    position: relative;
    left: 0;
    bottom: 0;
    margin-top: 15%;
}

.goTopButton:hover {
    cursor: pointer;
    animation: MoveUpDown 1s linear;
}

@keyframes MoveUpDown {
0%, 100% {
    bottom: 0;
}
30% {
    bottom: 20px;
}
}

.goTopText {
    margin-left: 95%;
}