.resumePage {
    background-color: rgb(255, 253, 250);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0 5% 0;
}

.resumeone {
    color: #EB8A3D;
    font-weight: bold;
    font-size: 20px;
}

.resumetwo {
    color: black;
    font-weight: bold;
    font-size: 35px;
}

.resumethree {
    color: gray;
}

.resumefour {
    color: #EB8A3D;
    font-size: 30px;
    margin-top: 5%;
}
.containerExp {
    width: 60%;
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: rgb(233, 233, 233);
}
.resume5 {
    color: rgb(58, 184, 198);
    font-size: 30px;
    font-weight: bold;
    margin-top: 3%;
}

.resume6 {
    color: rgb(16, 16, 16);
    font-size: 20px;
    font-weight: lighter;
}

.resume7 {
    color: rgb(131, 130, 130);
    font-size: 15px;
    font-weight: lighter;
}

.containerEdu {
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: rgb(233, 233, 233);
    width: 60%;
}
.resume8 {
    color: rgb(27, 27, 27);
    font-size: 30px;
    font-weight: bold;
    margin-top: 3%;
}

.resume9 {
    font-weight: lighter;
    font-size: 15px;
}


.educationContainer {
    width: 60%;
    margin-top: 3%;
    /* border-style: dashed;
    border-width: 5px;
    border-color: black; */
    display: flex;
    flex-direction: row;
}

.left1 {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    color: rgb(37, 37, 37);
    font-size: 25px;
    /* temporary border */
    width: 20%;
    margin-top: 1%;
}

.resume13 {
    
    margin-left: 15%;
}

.right1 {
}
.resume11 {
    font-weight: lighter;
    font-size: 30px;
    color: rgb(36, 36, 36);
}

.resume12 {
    font-weight: lighter;
    font-size: 20px;
    color: rgb(68, 68, 68);
}
