.projects {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgb(236, 236, 236);
    color: whitesmoke;
    height: 100vh;
}

.projects1 {
    color: #EB8A3D;
    font-weight: bold;
    font-size: 20px;
    margin: 5% 0 2% 0;
}

.projects2 {
    color: rgb(47, 47, 47);
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 4% 0;
}

.projectsContainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-bottom: 2%;
}

.object1 {
    background-color: rgb(236, 236, 236);
}

.object1:hover {
    -webkit-filter: brightness(70%);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}