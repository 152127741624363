.options {
    color: rgb(255, 255, 255);
    margin-right: 3%;
    margin-left: 3%;
    font-weight: bold;
    text-decoration: none;
}

.options:hover {
    color: #EB8A3D;
}

.navBar {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: white;
}

.links {
    text-align: center;
    align-items: center;
    justify-content: center;
}


